/* Urbanist Font */
@font-face {
  font-family: "Urbanist-Regular";
  src: url("../src/fonts/Urbanist-Regular.ttf") format("truetype");
  font-weight: 400 !important;
}

@font-face {
  font-family: "Urbanist-Medium";
  src: url("../src/fonts/Urbanist-Medium.ttf") format("truetype");
  font-weight: 500 !important;
}

@font-face {
  font-family: "Urbanist-Bold";
  src: url("../src/fonts/Urbanist-Bold.ttf") format("truetype");
  font-weight: 700 !important;
}

@font-face {
  font-family: "Urbanist-Semibold";
  src: url("../src/fonts/Urbanist-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist-Extrabold";
  src: url("../src/fonts/Urbanist-ExtraBold.ttf") format("truetype");
  font-weight: 800 !important;
}


/* Manrope Font */
@font-face {
  font-family: "Manrope-Regular";
  src: url("../src/fonts/Manrope-Regular.ttf") format("truetype");
  font-weight: 400 !important;
}

@font-face {
  font-family: "Manrope-Medium";
  src: url("../src/fonts/Manrope-Medium.ttf") format("truetype");
  font-weight: 500 !important;
}

@font-face {
  font-family: "Manrope-Bold";
  src: url("../src/fonts/Manrope-Bold.ttf") format("truetype");
  font-weight: 700 !important;
}

@font-face {
  font-family: "Manrope-Semibold";
  src: url("../src/fonts/Manrope-SemiBold.ttf") format("truetype");
}

body {
  font-family: "Urbanist-Regular", sans-serif;
  font-weight: 400 !important;
}

.sidebar-btn svg path{
  stroke: #0590A2;
}

.sidebar-btn:hover svg path{
  stroke: white;
}

.sidebar-btn.active svg path {
  stroke: white;
}

@media screen and (max-width: 768px) {
  .sidebar-btn svg{
    height: 20px;
    width: 20px;
  }
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 20px;
  aspect-ratio: 1;
  --_c:no-repeat radial-gradient(farthest-side, white 92%,#0000);
  background:
    var(--_c) top,
    var(--_c) left,
    var(--_c) right,
    var(--_c) bottom;
  background-size: 8px 8px;
  animation: l7 1s infinite;
}

.loader.primary {
  --_c:no-repeat radial-gradient(farthest-side, #53C2E3 92%,#0000);
}

@keyframes l7 {to{transform: rotate(.5turn)}}