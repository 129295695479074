@tailwind base;
@tailwind components;
@tailwind utilities;

.signatureCanvas {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
